<!-- Login.vue -->
<template>
    <div class="container">
      <div class="mb-container">
        <form @submit.prevent="login" v-if="user && user.login != 1">
          <div class="input-group-prepend">
            <input type="text" v-model="name" placeholder="Name" required/>
          </div>
          <div class="input-group-prepend">
            <input type="email" v-model="email" placeholder="Email" required/>
          </div>
          <div class="input-group-prepend">
            <input type="number" v-model="phone" placeholder="Phone Number" required/>
          </div>
          <div class="input-group-prepend">
            <input type="password" v-model="password" placeholder="Password" required/>
          </div>
          <div class="input-group-prepend">
            <input type="password" v-model="cpassword" placeholder="Confirm Password" required/>
          </div>
          <div class="input-group-prepend"> 
            <button type="submit">Register</button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  
  export default {
    data() {
      return {
        email: '', 
        cpassword:'',
        phone:'',
        password: '',
        name:'',
        user: JSON.parse(window.localStorage.getItem('user')),
      };
    },
    methods: {
      async login() {

        try {
          // Send the email and password to the API
          if (this.password !== this.cpassword) {
            alert("Password and Confirm Password do not match");
            return;
          }

          const response = await axios.post('https://djsnsapp.com/djsns/api/newuser', {
            email: this.email,
            password: this.password,
            name: this.name,
            phone: this.phone,
            device_type: 'web',
            fcm_id: "web"+(Math.random() + 1).toString(36).substring(7)
          });
          
          if(response.data.status === 200){
            // Handle the response from the API (e.g., store tokens, redirect, etc.)
            console.log('Login successful!', response);

            alert("Thank You For Registration");

            //router.push({ path: 'Login' })
            this.$router.push({ name: 'Login' })
    
            // Assuming the API returns an authentication token, you can save it in local storage or Vuex state for further requests.
            // Example: localStorage.setItem('token', response.data.token);
          }else{
            alert(response.data.message);
          }
  
        } catch (error) {
          // Handle login errors (e.g., show error message)
          console.error('Response Headers:', error.response.headers);

          // Handle login errors (e.g., show error message)
          console.error('Login failed:', error.response);
        }
      },
    },
  };
  </script>