<template>
    <div class="container">
      <loader v-if="loading"></loader>
      <!-- Display Subscription Details -->
      <!-- <div v-if="subscriptionDetails">
        <p>Subscription ID: {{ subscriptionDetails.id }}</p>
        <p>Status: {{ subscriptionDetails.status }}</p>
       Add more details as needed 
  Cancel Subscription Button 
        <button @click="cancelSubscription">Cancel Subscription</button>
      </div> -->
     

      <div v-if="responseAvailable"> 
        <!-- <div class="plan-details-box  mb-container">
          <div class="plan-name">Plan Name: Video Plan</div>
          <div class="plan-detail">Start Date: {{ PaymentStartDate }}</div>
          <div class="plan-detail">End Date: {{ PaymentEndDate }}</div>
          <div class="plan-detail">Status: {{ subscriptionStatus }}</div>
          <div class="plan-detail">Download: <button @click="downloadInvoice(subscriptionID)">Download Invoice</button></div>
        </div> -->
        <div class="card-deck mb-3 text-center mb-container">
          <div class="card mb-4 box-shadow mb-container" ref="invoiceContent">
              <div class="card-header">
                <h4 class="my-0 font-weight-normal">Video Plan</h4>
              </div>
              <div class="card-body">
                
                <ul class="list-unstyled mt-3 mb-4">
                  <li>Start Date: {{ PaymentStartDate }}</li>
                  <li>End Date: {{ PaymentEndDate }}</li>
                  <li>Status: {{ subscriptionStatus }}</li>
                  <li><button @click="downloadPdf()">Download Invoice</button></li>
                  <li v-if="subscriptionStatus ===  'ACTIVE'"><button @click="confirmCancelsuncription()">Cancle Subscription</button></li>
                </ul>
                <div id="paypal-button-container-P-37548089K3958141MMVGKJJI"></div>
              </div>
          </div>
          
        </div>
      </div>
      <div v-else>
        <p>No plan available yet.</p>
      </div>
    </div>
  </template>
  <style>
  .plan-details-box {
   /* White background color */
  padding: 20px; /* Add some padding for spacing */
  border: 1px solid #cccccc; /* Add a border for visual separation */
  width: 300px; /* Adjust the width as needed */
  color:#ffffff;
}

.plan-name {
  font-size: 18px; /* Set the font size for the Plan Name */
  font-weight: bold; /* Make it bold for emphasis */
  margin-bottom: 10px; /* Add margin for spacing */
  color:#ffffff;
}

.plan-detail {
  margin-bottom: 10px; /* Add margin between each detail for spacing */
  color:#ffffff;
}
  </style>
  <script>
  import axios from 'axios';

  export default {
    data() {
      return {
        subscriptionDetails: null,
        user: JSON.parse(window.localStorage.getItem('user')),
        subscriptionID:null,
        PaymentEndDate:null,
        PaymentStartDate:null,
        subscriptionStatus:null,
        invoice:null,
        responseAvailable: false,
        loading: false,
      };
    },
    async mounted() {
      await this.getSubscriptionDetails();
    },
    methods: {
      async getSubscriptionDetails() {
        
        try {
          this.loading = true;
            const response = await axios.post('https://djsnsapp.com/djsns/api/userplan', {
              user_id: this.user.user_id
            });
            if(response.data.status == 200){
              this.subscriptionStatus = response.data.subscriptionStatus;
              this.subscriptionID = response.data.subscriptionID;
              this.PaymentStartDate = response.data.PaymentStartDate;
              this.PaymentEndDate = response.data.PaymentEndDate;
              this.invoice = response.data.invoice;
              this.responseAvailable=true;
            }else{
              this.responseAvailable=false;
            }
            this.loading = false;
          } catch (error) {
            this.responseAvailable = false; // Set to false if there is an error or no response
            console.error('Error:', error);
          }

          //this.subscriptionDetails = response.data;



      },
      async confirmCancelsuncription(){
          if (confirm("Are you sure you want to cancel your subscription?")) {
              this.cancelSubscription();
          }
      },
      async cancelSubscription() {
        // const clientId = 'AbTxf_Xmynoa2eNQ74ewAABy-ciEqHTwccupgyc3AKsJtL8NGFWhI2k19s_V7Np_3slkxSsyeN5oZsKN';
        // const subscriptionId = 'I-S1HY7TVRHLR6';
  
        // try {
        //   // Make a request to your backend to get the PayPal access token securely
        //   const response = await axios.get('https://djsnsapp.com/djsns/api/gettoken', {
        //     clientId: clientId,
        //     subscriptionId: subscriptionId,
        //   });
  
        //   const accessToken = response.data;
  
        //   // Now make a request to cancel the subscription using the access token
        //   await axios.post(
        //     `https://api.sandbox.paypal.com/v1/billing/subscriptions/${subscriptionId}/cancel`,
        //     {},
        //     {
        //       headers: {
        //         Authorization: `Bearer ${accessToken}`,
        //       },
        //     }
        //   );
        try{
          this.loading = true;
          await axios.post('https://djsnsapp.com/djsns/api/cancelsubscription', {
            user_id: this.user.user_id,
            subscription_id:this.subscriptionID,
          });
        
          //console.log("cancel",response.data);
          // Update your Vue component state or perform any necessary actions after cancellation
          alert('Subscription canceled successfully');

          this.getSubscriptionDetails();
          
        } catch (error) {
          alert('Error canceling subscription:', error);
        }
      },
      async downloadPdf() {
        const pdfUrl = this.invoice;
        window.open(pdfUrl, '_blank');
        // axios
        //   .get(pdfUrl, { responseType: 'arraybuffer' })
        //   .then((response) => {
        //     this.savePdf(response.data);
        //   })
        //   .catch((error) => {
        //     console.error('Error downloading PDF:', error);
        //   });
      },
      async savePdf(pdfData) {
        const blob = new Blob([pdfData], { type: 'application/pdf' });

        // Create a download link
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'payment_2326.pdf';

        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
      },
    },
  };
  </script>
  