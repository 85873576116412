import * as firebase from "firebase";
import "firebase/database";

// let config = {
//     apiKey: "AIzaSyBMJP1Gf9Sh5ZdlBp7c0l0R_kpXjlGHSIg",
//     authDomain: "djsns-e0cbb.firebaseapp.com",
//     projectId: "djsns-e0cbb",
//     storageBucket: "djsns-e0cbb.appspot.com",
//     messagingSenderId: "849402937717",
//     appId: "1:849402937717:web:a8ef84bdd59be4cb1d933c",
//     measurementId: "G-WTPQ2M22BQ"
// };

let config = {
    apiKey: "AIzaSyDvQ0eCInAY3OWVbojMLzuGo02WB53GSYE",
    authDomain: "dj-sns.firebaseapp.com",
    databaseURL: "https://dj-sns-default-rtdb.firebaseio.com",
    projectId: "dj-sns",
    storageBucket: "dj-sns.appspot.com",
    messagingSenderId: "225484636554",
    appId: "1:225484636554:web:12040f92702a916a640873",
    measurementId: "G-MM54X7TNC6"
};

firebase.initializeApp(config);

export default firebase.database();