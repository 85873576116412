<!-- Login.vue -->
<template>
  <div>
    <div class="container">
      <div class="popup-overlay" v-if="isVisible"></div>
      <div class="mb-container">
          <!-- <div>
            <img src="https://djsnsapp.com/djsns/storage/uploads/logo_20231103094733.png"/>
          </div> -->
          <form @submit.prevent="login" v-if="!isLoggedIn">
            <div class="input-group-prepend">
              <input type="email" v-model="email" placeholder="Email" style="color:white" required>
            </div>
            <div class="input-group-prepend">
              <input type="password" v-model="password" placeholder="Password" style="color:white" required>
            </div>
            <div class="input-group-prepend flx_around">
              <button type="submit">Login</button>
              <router-link to="/forgotpass"><button type="submit">Forgot Password</button></router-link>
            </div>
          </form>
          <p v-else>
            You are already logged in. <!-- Display a message or redirect the user -->
          </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { EventBus } from '../../_helper/EventBus.js';

export default {
  data() {
    return {
      email: '',
      password: '',
      loading: false
    };
  },
  computed: {
    isLoggedIn() {
      const user = JSON.parse(localStorage.getItem('user'));
      return user && user.login === 1;
    },
  },
  methods: {
    async login() {
      try {
        // Send the email and password to the API
        this.loading=true;
        const response = await axios.post('https://djsnsapp.com/djsns/api/loginuser', {
          email: this.email,
          password: this.password,
        });

        // Handle the response from the API (e.g., store tokens, redirect, etc.)
        if(response.data.status || response.data.status === 200){
          console.log('Login successful!', response.data.data.user_id);

          const currentToken = JSON.parse(localStorage.getItem('user'));

          const newToken = {
            user_id: response.data.data.user_id,
            device_type: 'web',
            token: response.data.data.token,
            login: 1,
          };

          const updatedUser = { ...currentToken, ...newToken };

          localStorage.removeItem('user');

          // Store the updated user object back in local storage
          localStorage.setItem('user', JSON.stringify(updatedUser));

          EventBus.$emit('userLoggedIn');
          // Assuming the API returns an authentication token, you can save it in local storage or Vuex state for further requests.
          // Example: localStorage.setItem('token', response.data.token);
          //this.loading=true;
          this.$router.push({ name: 'Home' }); 
        }else{
          alert(response.data.message);
        }

      } catch (error) {
        // Handle login errors (e.g., show error message)
        
        console.error('Login failed:', error.response);
        alert(error.response.data.message);
      }
    },
  },
};
</script>
