<!-- Login.vue -->
<template>
    
    <div class="container">
    <div class="popup-overlay" v-if="isVisible"></div>
      <div class="mb-container">
        <form @submit.prevent="login" v-if="user && user.login != 1">
          
          <div class="input-group-prepend">
            <input type="email" v-model="email" placeholder="Email" required/>
          </div>
          <div class="input-group-prepend"> 
            <button type="submit">GET PASSWORD</button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import { EventBus } from '../../_helper/EventBus.js';
  
  export default {
    data() {
      return {
        email: '', 
       
        user: JSON.parse(window.localStorage.getItem('user')),
        loading: false,
        
      };
    },
    created() {
		EventBus.$on('Forgotpasswords');
		
	},
    methods: {
      async login() {
        this.loading=true;
        try {
          // Send the email and password to the API
         
          this.loading = true;
          const response = await axios.post('https://djsnsapp.com/djsns/api/forgotpass', {
            email: this.email,
            
          });
          
          if(response.data.status === 200){
          
            EventBus.$emit('Forgotpasswords');
           
            this.$router.push({ name: 'Login' })
            this.loading = false;
            
          }else{
            alert(response.data.message);
          }
  
        } catch (error) {
          // Handle login errors (e.g., show error message)
          console.error('Response Headers:', error.response.headers);

          // Handle login errors (e.g., show error message)
          console.error('Login failed:', error.response);
          this.loading = false;
        }
      },
    },
  };
  </script>