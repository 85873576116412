<!-- Popup.vue -->
<template>
    <div class="popup-overlay" v-if="isVisible">
      <div class="mb-container">
        <div class="popup-content">
          <p>{{ popupText }}</p><br/>
          <button @click="closePopup">Close</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isVisible: false,
        popupText: "",
      };
    },
    methods: {
      openPopup(text) {
        this.popupText = text;
        this.isVisible = true;
      },
      closePopup() {
        this.isVisible = false;
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add styling for the popup and overlay */
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
  }
  
  .popup-content {
    text-align: center;
  }
  </style>
  