<template>
    <div class="container">
      <loader v-if="loading"></loader>
      <div class="mb-container">
        <h1 v-if="subscriptionStatus == 'ACTIVE'">Video Plan is Activated</h1>
        <h1 v-else>No Video Plan is Activated</h1>
        <hr/>
        <form @submit.prevent="updateProfile" v-if="user && user.login === 1">
          <!-- <div class="input-group-prepend" v-if="pimage !== null">
            <img
              v-if="!previewImage"
              :src="pimage"
              style="height: 300px; width: 300px; border-radius: 150px; margin: 0px auto;"
            />
            <img
              v-if="previewImage"
              :src="previewImage"
              style="height: 300px; width: 300px; border-radius: 150px; margin: 0px auto;"
            />
          </div> -->
          <div class="input-group-prepend">
            <input type="file" @change="handleFileChange" style="opacity: 0px; display: none;" />
          </div>
          <div class="input-group-prepend">
            <input type="text" v-model="name" placeholder="Name" required />
          </div>
          <div class="input-group-prepend">
            <input type="email" v-model="email" placeholder="Email" readonly />
          </div>
          <div class="input-group-prepend">
            <input type="number" v-model="phone" placeholder="Phone Number" required />
          </div>
          <div class="input-group-prepend">
            <button type="submit">Update</button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        subscriptionStatus: null,
        user: JSON.parse(window.localStorage.getItem('user')),
        name: null,
        email: null,
        phone: null,
        pimage: null,
        loading: false,
        previewImage: null, // Add a variable to store the preview image
      };
    },
    async mounted() {
      this.getSubscriptionDetails();
    },
    methods: {
      async getSubscriptionDetails() {
        try {
          this.loading = true;
          //this is commented as of now for subscription plan

          const response = await axios.post('https://djsnsapp.com/djsns/api/userplan', {
            user_id: this.user.user_id,
          });
          if (response.data.status == 200) {
            this.subscriptionStatus = response.data.subscriptionStatus;
          }

          //user plan api end 
  
          const profile = await axios.post('https://djsnsapp.com/djsns/api/userprofile', {
            user_id: this.user.user_id,
          });
  
          if (profile.data.status == 200) {
            this.name = profile.data.data.name;
            this.email = profile.data.data.email;
            this.phone = profile.data.data.phone;
            this.pimage = profile.data.data.profile;
          }
          this.loading = false;
        } catch (error) {
          console.error('Error:', error);
        }
      },
      async updateProfile() {
        this.loading = true;
        try {
          const formData = new FormData();
          formData.append('email', this.email);
          formData.append('name', this.name);
          formData.append('phone', this.phone);
          formData.append('user_id', this.user.user_id);
          if (this.pimage) {
            formData.append('pimage', this.pimage);
          }
  
          const response = await axios.post('https://djsnsapp.com/djsns/api/updateprofile', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
  
          if (response.data.status === 200) {
            alert(response.data.message);
            this.getSubscriptionDetails();
          }
        } catch (error) {
          console.error('Error:', error);
        } finally {
          this.loading = false;
        }
      },
      handleFileChange(event) {
        // Handle the file change event
        const file = event.target.files[0];
        this.previewImage = URL.createObjectURL(file); // Set the preview image
        this.pimage = file; // Set the selected file
      },
    },
  };
  </script>
  